/*
* Auth
*/

@use "../../variables.scss" as variables;

.signin-container {
    background: transparent
        linear-gradient(
            122deg,
            variables.$ghost-white 0%,
            variables.$alice-blue 100%
        )
        0% 0% no-repeat padding-box;
}

.signin-text {
    font-family: variables.$font-family-display;
    font-weight: variables.$font-weight-normal;
    font-size: 3em;
}

.register-text {
    font-family: variables.$font-family-public-sans;
    font-weight: variables.$font-weight-bold;
    color: variables.$primary-color-blue;
    text-decoration: none;
}
