.modal-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: 0;
    z-index: 10000; //TODO: use bootstrap variable here
    background: rgba(0, 0, 0, 0.552);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(3px);
}

.btn-modal-close{
    width: 40px;
    height: 40px;
    opacity: 1;

    &:hover{
        cursor: pointer;
        opacity: 0.75;
    }
}
