@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400..900;1,400..900&family=Space+Grotesk:wght@300..700&display=swap");

// Color
// Primary Colors
$primary-teal: #007c85;
$primary-dark: #141415;
$primary-white: #ffffff;
$primary-cream: #faf3e1;

// Secondary Colors with opacity variations
$secondary-dark-70: rgba(29, 31, 30, 0.7); // #1DIFIE 70%
$secondary-dark-75: rgba(29, 31, 30, 0.75); // #1DIFIE 75%
$secondary-dark-80: rgba(29, 31, 30, 0.8); // #1DIFIE 80%
$secondary-dark-10: rgba(29, 31, 30, 0.1); // #1DIFIE 10%
$secondary-dark: #1d1f1e;

// Utility Colors
$black-10: rgba(0, 0, 0, 0.1);
$black-40: rgba(0, 0, 0, 0.4);
$black-60: rgba(0, 0, 0, 0.6);
$white-85: rgba(255, 255, 255, 0.85);

// Special Colors
$forest-green: #022c22;
$mint-green: #bef264;
$pink: #ef4776;
$aqua: #d4fcff;
$cream-light: #fbf5e7;
$gray: #ced9d6;
$deep-green: #084133;

// Other colors
$border-color: 0000001a;
// Gradients
$brown-gradient: linear-gradient(180deg, #342010 100%, #241708 0%);

$success: #01cb8e;
$success-tint-100: #ccf4e8;
$success-tint-200: #99ead2;
$success-tint-300: #66e0bb;
$success-tint-400: #33d5a4;

$danger: #ff4d4f;
$danger-tint-100: #ffd5d5;
$danger-tint-200: #ffabab;
$danger-tint-300: #ff8182;
$danger-tint-400: #ff5858;

$information: #70b8ff;
$information-tint-100: #e2f1ff;
$information-tint-200: #c6e3ff;
$information-tint-300: #a8d4ff;
$information-tint-400: #8bc6ff;

$alert: #ffb302;
$alert-tint-100: #fff0cc;
$alert-tint-200: #ffe199;
$alert-tint-300: #ffd167;
$alert-tint-400: #ffc234;

$light-blue: #a8d4ff;
$light-purple: #a19ff3;
$light-green: #66e0bb;
$light-red: #ff8182;
$yellow: #ffd167;
$light-brown: #ac9a81;
$blue: #7291e5;
$light-purple: #df8fdf;
$light-orange: #efb37f;
$light-green: #b5e69a;
$white: #f7fafa;

// Typography
$font-family-display: "Playfair Display", serif;
$font-family-primary: "Space Grotesk", sans-serif;

$font-weight-lighter: lighter !default;
$font-weight-light: 300 !default;
$font-weight-normal: 400 !default;
$font-weight-medium: 500 !default;
$font-weight-semibold: 600 !default;
$font-weight-bold: 700 !default;
$font-weight-bolder: 800 !default;
$font-weight-black: 900 !default;

// Typography Scale
$base-font-size: 16px;

// Font Sizes - Mobile First
$font-sizes: (
    "xxs": 0.625rem,
    // 10px
    "xs": 0.75rem,
    // 12px
    "sm": 0.875rem,
    // 14px
    "base": 1rem,
    // 16px
    "lg": 1.125rem,
    // 18px
    "xl": 1.25rem,
    // 20px
    "2xl": 1.5rem,
    // 24px
    "3xl": 1.875rem,
    // 30px
    "4xl": 2.25rem,
    // 36px
    "5xl": 3rem,
    // 48px
    "6xl": 3.75rem, // 60px
);

// Line Heights
$line-height-tight: 1.25;
$line-height-normal: 1.5;
$line-height-relaxed: 1.75;

// Letter Spacing
$letter-spacing-tight: -0.025em;
$letter-spacing-normal: 0;
$letter-spacing-wide: 0.025em;

// Responsive Typography Mixins
@mixin typography-h1 {
    font-size: map-get($font-sizes, "4xl");
    line-height: $line-height-tight;
    letter-spacing: $letter-spacing-tight;

    @media (min-width: 768px) {
        font-size: map-get($font-sizes, "5xl");
    }

    @media (min-width: 1024px) {
        font-size: map-get($font-sizes, "6xl");
    }
}

@mixin typography-h2 {
    font-size: map-get($font-sizes, "3xl");
    line-height: $line-height-tight;

    @media (min-width: 768px) {
        font-size: map-get($font-sizes, "4xl");
    }
}

@mixin typography-h3 {
    font-size: map-get($font-sizes, "2xl");
    line-height: $line-height-tight;

    @media (min-width: 768px) {
        font-size: map-get($font-sizes, "3xl");
    }
}

@mixin typography-body {
    font-size: map-get($font-sizes, "base");
    line-height: $line-height-normal;

    @media (min-width: 768px) {
        font-size: map-get($font-sizes, "lg");
    }
}

@mixin typography-small {
    font-size: map-get($font-sizes, "sm");
    line-height: $line-height-normal;
}

@mixin typography-smallest {
    font-size: map-get($font-sizes, "xxs");
    line-height: $line-height-normal;
}

// Typography Mixins for Headings
@mixin typography-h1 {
    font-family: $font-family-primary;
    font-size: map-get($font-sizes, "4xl");
    line-height: $line-height-tight;
    letter-spacing: $letter-spacing-tight;
    font-weight: $font-weight-bold;

    @media (min-width: 768px) {
        font-size: map-get($font-sizes, "5xl");
    }

    @media (min-width: 1024px) {
        font-size: map-get($font-sizes, "6xl");
    }
}

@mixin typography-h2 {
    font-family: $font-family-primary;
    font-size: map-get($font-sizes, "3xl");
    line-height: $line-height-tight;
    letter-spacing: $letter-spacing-tight;
    font-weight: $font-weight-semibold;

    @media (min-width: 768px) {
        font-size: map-get($font-sizes, "4xl");
    }
}

@mixin typography-h3 {
    font-family: $font-family-primary;
    font-size: map-get($font-sizes, "2xl");
    line-height: $line-height-tight;
    letter-spacing: $letter-spacing-normal;
    font-weight: $font-weight-medium;

    @media (min-width: 768px) {
        font-size: map-get($font-sizes, "3xl");
    }
}

@mixin typography-h4 {
    font-family: $font-family-primary;
    font-size: map-get($font-sizes, "xl");
    line-height: $line-height-normal;
    letter-spacing: $letter-spacing-normal;
    font-weight: $font-weight-medium;

    @media (min-width: 768px) {
        font-size: map-get($font-sizes, "2xl");
    }
}

@mixin typography-h5 {
    font-family: $font-family-primary;
    font-size: map-get($font-sizes, "lg");
    line-height: $line-height-normal;
    letter-spacing: $letter-spacing-normal;
    font-weight: $font-weight-medium;

    @media (min-width: 768px) {
        font-size: map-get($font-sizes, "xl");
    }
}

@mixin typography-h6 {
    font-family: $font-family-primary;
    font-size: map-get($font-sizes, "base");
    line-height: $line-height-normal;
    letter-spacing: $letter-spacing-normal;
    font-weight: $font-weight-medium;

    @media (min-width: 768px) {
        font-size: map-get($font-sizes, "lg");
    }
}

// Spacing System
$spacing: (
    "xs": 0.25rem,
    // 4px
    "sm": 0.5rem,
    // 8px
    "md": 1rem,
    // 16px
    "lg": 1.5rem,
    // 24px
    "xl": 2rem,
    // 32px
    "2xl": 3rem,
    // 48px
    "3xl": 4rem, // 64px
);

// Breakpoints
$breakpoints: (
    "sm": 640px,
    "md": 768px,
    "lg": 1024px,
    "xl": 1280px,
    "2xl": 1536px,
);

// Z-index Scale
$z-index: (
    "modal": 1000,
    "overlay": 900,
    "dropdown": 800,
    "header": 700,
    "tooltip": 600,
);

// Border Radius
$border-radius: (
    "none": 0,
    "sm": 0.125rem,
    // 2px
    "md": 0.25rem,
    // 4px
    "lg": 0.5rem,
    // 8px
    "xl": 1rem,
    // 16px
    "full": 9999px,
);

// Default border radius for Bootstrap
$border-radius: 0.25rem;
$border-radius-sm: 0.125rem;
$border-radius-lg: 0.5rem;
$border-radius-xl: 1rem;
$border-radius-2xl: 2rem;
$border-radius-pill: 50rem;

// Our custom border radius map
$border-radius-sizes: (
    "none": 0,
    "sm": 0.125rem,
    // 2px
    "md": 0.25rem,
    // 4px
    "lg": 0.5rem,
    // 8px
    "xl": 1rem,
    // 16px
    "full": 9999px,
);

// Shadows
$shadows: (
    "sm": "0 1px 2px 0 rgba(0, 0, 0, 0.05)",
    "md": "0 4px 6px -1px rgba(0, 0, 0, 0.1)",
    "lg": "0 10px 15px -3px rgba(0, 0, 0, 0.1)",
    "xl": "0 20px 25px -5px rgba(0, 0, 0, 0.1)",
);

// Transitions
$transition-duration: 0.2s;
$transition-timing: ease-in-out;

// Container
$container-max-widths: (
    "sm": 640px,
    "md": 768px,
    "lg": 1024px,
    "xl": 1280px,
    "2xl": 1536px,
);

// Mixins for responsive design
@mixin breakpoint($breakpoint) {
    $size: map-get($breakpoints, $breakpoint);
    @media (min-width: $size) {
        @content;
    }
}

// Mixin for shadows
@mixin shadow($size) {
    box-shadow: map-get($shadows, $size);
}

// Device Breakpoints
$breakpoints: (
    "xs": 320px,
    "sm": 375px,
    "md": 768px,
    "lg": 1024px,
    "xl": 1280px,
    "2xl": 1440px,
);

// Container Widths
$container-padding: (
    "xs": 16px,
    "sm": 20px,
    "md": 32px,
    "lg": 64px,
    "xl": 80px,
);

// Grid System
$grid-columns: 12;
$grid-gutter: (
    "xs": 16px,
    "sm": 20px,
    "md": 24px,
    "lg": 32px,
);

// Responsive Mixins
@mixin mobile-only {
    @media (max-width: map-get($breakpoints, "sm")) {
        @content;
    }
}

@mixin tablet-only {
    @media (min-width: map-get($breakpoints, "md")) and (max-width: calc(map-get($breakpoints, "lg") - 1px)) {
        @content;
    }
}

@mixin desktop-only {
    @media (min-width: map-get($breakpoints, "lg")) {
        @content;
    }
}

@mixin desktop-large {
    @media (min-width: map-get($breakpoints, "xl")) {
        @content;
    }
}

@mixin responsive-padding {
    padding-left: map-get($container-padding, "xs");
    padding-right: map-get($container-padding, "xs");

    @include tablet-only {
        padding-left: map-get($container-padding, "md");
        padding-right: map-get($container-padding, "md");
    }

    @include desktop-only {
        padding-left: map-get($container-padding, "lg");
        padding-right: map-get($container-padding, "lg");
    }
}

@mixin responsive-container {
    width: 100%;
    margin: 0 auto;
    @include responsive-padding;

    @include tablet-only {
        max-width: map-get($breakpoints, "md");
    }

    @include desktop-only {
        max-width: map-get($breakpoints, "lg");
    }
}

// Device-specific font sizes
$font-sizes-mobile: (
    "xs": 0.75rem,
    "sm": 0.875rem,
    "base": 1rem,
    "lg": 1.125rem,
    "xl": 1.25rem,
    "2xl": 1.5rem,
    "3xl": 1.875rem,
    "4xl": 2.25rem,
    // 36px
    "5xl": 3rem,
    // 48px
    "6xl": 3.75rem, // 60px
);

$font-sizes-tablet: (
    "xs": 0.875rem,
    "sm": 1rem,
    "base": 1.125rem,
    "lg": 1.25rem,
    "xl": 1.5rem,
    "2xl": 1.875rem,
    "3xl": 2.25rem,
    // 36px
    "4xl": 3rem,
    // 48px
    "5xl": 3.75rem,
    "6xl": 4.5rem,
);

$font-sizes-desktop: (
    "xs": 1rem,
    "sm": 1.125rem,
    "base": 1.25rem,
    "lg": 1.5rem,
    "xl": 1.875rem,
    "2xl": 2.25rem,
    "3xl": 3rem,
    // 48px
    "4xl": 3.75rem,
    // 60px
    "5xl": 4.5rem,
    // 72px
    "6xl": 6rem, // 96px
);

@mixin responsive-font-size($size) {
    font-size: map-get($font-sizes-mobile, $size);

    @include tablet-only {
        font-size: map-get($font-sizes-tablet, $size);
    }

    @include desktop-only {
        font-size: map-get($font-sizes-desktop, $size);
    }
}
