@use "../../../variables.scss" as variables;

.chart-card {
    border-radius: 12px !important;
    border: 1px solid variables.$border-color !important;
    background-color: variables.$white !important;
    box-shadow: none !important;
}

.link-button {
    color: variables.$primary-color-blue-gray;
    font-family: variables.$font-family-public-sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 21px /* 150% */;
    letter-spacing: 0.21px;
    cursor: pointer;
}
