/*
* Sidebar
*/

@use "../../variables.scss" as variables;

* {
    padding: 0%;
    margin: 0%;
    box-sizing: border-box;
    font-family: variables.$font-family-public-sans;
}
.sidebar {
    height: 100%;
    width: 78px;
    padding: 6px 14px;
    z-index: 99;
    background-color: variables.$secondary-color-blue-50;
    transition: all 0.5s ease;
    position: fixed;
    top: 59px;
    left: 0;
    box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.1);
}

.sidebar.open {
    width: 280px;
}

.sidebar .logo_details {
    height: 60px;
    display: flex;
    align-items: center;
    position: relative;
}

.sidebar .logo_details .icon {
    opacity: 0;
    transition: all 0.5s ease;
}

.sidebar .logo_details .project-title {
    color: variables.$primary-color-dark-blue;
    font-size: 1em;
    font-weight: variables.$font-weight-semibold;
    opacity: 0;
    transition: all 0.5s ease;
}

.sidebar .logo_details .project-title .project-logo {
    /* Center the content */
    align-items: center;
    display: flex;
    justify-content: center;

    /* Colors */
    background-color: variables.$primary-color-blue-gray;
    color: #fff;

    /* Rounded border */
    border-radius: 50%;
    height: 2.5rem;
    width: 2.5rem;
    font-family: variables.$font-family-public-sans;

    img {
        border-radius: 50%;
        object-fit: cover;
        height: 100%;
        width: 100%;
    }
}

.sidebar.open .logo_details .icon,
.sidebar.open .logo_details .project-title {
    opacity: 1;
}

.sidebar .logo_details #btn {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    font-size: 23px;
    text-align: center;
    cursor: pointer;
    transition: all 0.5s ease;
}

.sidebar.open .logo_details #btn {
    text-align: right;
}

.sidebar i {
    color: var(white);
    height: 60px;
    line-height: 60px;
    min-width: 50px;
    font-size: 1.5625em;
    text-align: center;
}

.sidebar .nav-list {
    // margin-;
    // height: 100%;
    cursor: pointer;
}

.sidebar li {
    position: relative;
    margin: 8px 0;
    list-style: none;
}

.sidebar li .tooltip {
    position: absolute;
    top: -20px;
    left: calc(100% + 15px);
    z-index: 3;
    background-color: var(white);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
    padding: 6px 14px;
    font-size: 15px;
    font-weight: variables.$font-weight-normal;
    border-radius: 5px;
    white-space: nowrap;
    opacity: 0;
    pointer-events: none;
}

.sidebar li:hover .tooltip {
    opacity: 1;
    pointer-events: auto;
    transition: all 0.4s ease;
    top: 50%;
    transform: translateY(-50%);
}

.sidebar.open li .tooltip {
    display: none;
}

.sidebar li a {
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
    text-decoration: none;
    position: relative;
    transition: all 0.5s ease;
    z-index: 12;
}

.sidebar li a::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    transform: scaleX(0);
    background-color: var(white);
    border-radius: 5px;
    transition: transform 0.3s ease-in-out;
    transform-origin: left;
    z-index: -2;
}

.sidebar li a:hover::after {
    transform: scaleX(1);
    color: variables.$primary-night;
    cursor: pointer;
}

.sidebar li a .link_name {
    color: variables.$primary-color-dark-blue;
    font-size: 15px;
    font-weight: variables.$font-weight-medium;
    white-space: nowrap;
    pointer-events: auto;
    transition: all 0.4s ease;
    pointer-events: none;
    opacity: 0;
}

.sidebar li a:hover .link_name,
.sidebar li a:hover i {
    transition: all 0.5s ease;
    color: variables.$primary-color-dark-blue;
}

.sidebar.open li a .link_name {
    opacity: 1;
    pointer-events: auto;
}

.sidebar li i {
    height: 35px;
    line-height: 35px;
    font-size: 18px;
    border-radius: 5px;
}

.content-area {
    position: relative;
    background-color: variables.$background-color;
    min-height: 100vh;
    top: 0;
    left: 78px;
    width: calc(100% - 78px);
    transition: all 0.5s ease;
    // z-index: 2;
}

.sidebar.open ~ .content-area {
    left: 280px;
    width: calc(100% - 280px);
}

.sidebar .nav-link.active {
    background-color: variables.$sidebar-hover-color;
    color: variables.$primary-color-dark-blue;
    font-weight: variables.$font-weight-bold;
    border-radius: 12px;
    width: 100%;
    font-family: variables.$font-family-public-sans;
    font-size: 0.875em;
}

.bottom {
    bottom: 50px;
    width: 100%;
    padding: 10px;
}

.stop {
    color: #fff;
    background-color: variables.$danger !important;

    &:hover {
        background-color: variables.$danger-tint-300 !important;
    }

    &:focus {
        background-color: variables.$danger-tint-300 !important;
    }
}

.start {
    color: #fff;
    background-color: variables.$primary-color-blue !important;

    &:hover {
        background-color: variables.$primary-color-blue-gray !important;
    }

    &:focus {
        background-color: variables.$primary-color-blue-gray !important;
    }
}

.project-title-container {
    max-width: 7rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;

    cursor: pointer;

    &:hover {
        .tooltip {
            opacity: 1;
            visibility: visible;
        }
    }
}
