@use "./../../../../variables.scss" as variables;


.checkout-model {
    // Default styles for mobile devices
    width: 100% !important;

    // Styles for tablets and small desktops
    @media (min-width: 768px) {
        width: 300px !important;
    }

    // Styles for large desktops
    @media (min-width: 1024) {
        width: 800px !important;
    }
}
