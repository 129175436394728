@use "../../variables.scss" as variables;

.skeleton-title {
    /* Add styles for the skeleton title */
    // height: 20px;
    width: 8%;
    background-image: -webkit-linear-gradient(
        left,
        variables.$secondary-slate-blue-tint-300 0px,
        variables.$secondary-slate-blue-tint-400 40px,
        variables.$secondary-slate-blue-tint-300 80px
    );
    background-image: -o-linear-gradient(
        left,
        variables.$secondary-slate-blue-tint-300 0px,
        variables.$secondary-slate-blue-tint-400 40px,
        variables.$secondary-slate-blue-tint-300 80px
    );
    background-image: linear-gradient(
        90deg,
        variables.$secondary-slate-blue-tint-300 0px,
        variables.$secondary-slate-blue-tint-400 40px,
        variables.$secondary-slate-blue-tint-300 80px
    );
    background-size: 250px;
    -webkit-animation: shine-loading-container-items 2s infinite ease-out;
    animation: shine-loading-container-items 2s infinite ease-out;
    margin-bottom: 10px;
}

.skeleton-h1 {
    /* Add styles for the skeleton h1 element */
    height: 40px;
    width: 250px;
    background-image: -webkit-linear-gradient(
        left,
        variables.$secondary-slate-blue-tint-300 0px,
        variables.$secondary-slate-blue-tint-400 40px,
        variables.$secondary-slate-blue-tint-300 80px
    );
    background-image: -o-linear-gradient(
        left,
        variables.$secondary-slate-blue-tint-300 0px,
        variables.$secondary-slate-blue-tint-400 40px,
        variables.$secondary-slate-blue-tint-300 80px
    );
    background-image: linear-gradient(
        90deg,
        variables.$secondary-slate-blue-tint-300 0px,
        variables.$secondary-slate-blue-tint-400 40px,
        variables.$secondary-slate-blue-tint-300 80px
    );
    background-size: 250px;
    -webkit-animation: shine-loading-container-items 2s infinite ease-out;
    animation: shine-loading-container-items 2s infinite ease-out;
}

.skeleton-subtitle {
    /* Add styles for the skeleton subtitle element */
    height: 17px;
    width: 60%;
    background-image: -webkit-linear-gradient(
        left,
        variables.$secondary-slate-blue-tint-300 0px,
        variables.$secondary-slate-blue-tint-400 40px,
        variables.$secondary-slate-blue-tint-300 80px
    );
    background-image: -o-linear-gradient(
        left,
        variables.$secondary-slate-blue-tint-300 0px,
        variables.$secondary-slate-blue-tint-400 40px,
        variables.$secondary-slate-blue-tint-300 80px
    );
    background-image: linear-gradient(
        90deg,
        variables.$secondary-slate-blue-tint-300 0px,
        variables.$secondary-slate-blue-tint-400 40px,
        variables.$secondary-slate-blue-tint-300 80px
    );
    background-size: 250px;
    -webkit-animation: shine-loading-container-items 2s infinite ease-out;
    animation: shine-loading-container-items 2s infinite ease-out;
}

.skeleton-button {
    /* Add styles for the skeleton button */
    height: 40px;
    width: 200px;
    background-image: -webkit-linear-gradient(
        left,
        variables.$secondary-slate-blue-tint-300 0px,
        variables.$secondary-slate-blue-tint-400 40px,
        variables.$secondary-slate-blue-tint-300 80px
    );
    background-image: -o-linear-gradient(
        left,
        variables.$secondary-slate-blue-tint-300 0px,
        variables.$secondary-slate-blue-tint-400 40px,
        variables.$secondary-slate-blue-tint-300 80px
    );
    background-image: linear-gradient(
        90deg,
        variables.$secondary-slate-blue-tint-300 0px,
        variables.$secondary-slate-blue-tint-400 40px,
        variables.$secondary-slate-blue-tint-300 80px
    );
    background-size: 250px;
    -webkit-animation: shine-loading-container-items 2s infinite ease-out;
    animation: shine-loading-container-items 2s infinite ease-out;
}

.skelton-card {
    border-radius: 0px;
    border: 1px solid variables.$secondary-slate-blue-tint-300;
    box-shadow: -6px 12px 32px #0b0b0f0a;
    background-color: variables.$white;
    overflow: hidden;
}

.skeleton-circular-border {
    /* Center the content */
    align-items: center;
    display: flex;
    justify-content: center;

    /* Colors */
    border-radius: 50%;
    background-color: variables.$secondary-slate-blue-tint-200;
    color: #fff;

    /* Rounded border */
    border-radius: 50%;
    height: 2.5rem;
    width: 2.5rem;
}

.skeleton-card-img-top {
    object-position: center;
    border-radius: 50%;
    background-color: variables.$secondary-slate-blue-tint-200;
    -webkit-animation: shine-loading-image 2s infinite ease-out;
    animation: shine-loading-image 2s infinite ease-out;
}

.skeleton-card-body {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.skeleton-card-title {
    background-color: variables.$secondary-slate-blue-tint-200;
    width: 120px;
    height: 20px;
    background-image: -webkit-linear-gradient(
        left,
        variables.$secondary-slate-blue-tint-200 0px,
        variables.$secondary-slate-blue-tint-300 40px,
        variables.$secondary-slate-blue-tint-200 80px
    );
    background-image: -o-linear-gradient(
        left,
        variables.$secondary-slate-blue-tint-200 0px,
        variables.$secondary-slate-blue-tint-300 40px,
        variables.$secondary-slate-blue-tint-200 80px
    );
    background-image: linear-gradient(
        90deg,
        variables.$secondary-slate-blue-tint-200 0px,
        variables.$secondary-slate-blue-tint-300 40px,
        variables.$secondary-slate-blue-tint-200 80px
    );
    background-size: 250px;
    -webkit-animation: shine-loading-container-items 2s infinite ease-out;
    animation: shine-loading-container-items 2s infinite ease-out;
}

.skeleton-card-subtitle {
    background-color: variables.$secondary-slate-blue-tint-200;
    width: 95%;
    height: 50px;
    background-image: -webkit-linear-gradient(
        left,
        variables.$secondary-slate-blue-tint-200 0px,
        variables.$secondary-slate-blue-tint-300 40px,
        variables.$secondary-slate-blue-tint-200 80px
    );
    background-image: -o-linear-gradient(
        left,
        variables.$secondary-slate-blue-tint-200 0px,
        variables.$secondary-slate-blue-tint-300 40px,
        variables.$secondary-slate-blue-tint-200 80px
    );
    background-image: linear-gradient(
        90deg,
        variables.$secondary-slate-blue-tint-200 0px,
        variables.$secondary-slate-blue-tint-300 40px,
        variables.$secondary-slate-blue-tint-200 80px
    );
    background-size: 250px;
    -webkit-animation: shine-loading-container-items 2s infinite ease-out;
    animation: shine-loading-container-items 2s infinite ease-out;
}

.skeleton-card-link {
    background-color: variables.$secondary-slate-blue-tint-200;
    width: 80px;
    height: 20px;
    background-image: -webkit-linear-gradient(
        left,
        variables.$secondary-slate-blue-tint-200 0px,
        variables.$secondary-slate-blue-tint-300 40px,
        variables.$secondary-slate-blue-tint-200 80px
    );
    background-image: -o-linear-gradient(
        left,
        variables.$secondary-slate-blue-tint-200 0px,
        variables.$secondary-slate-blue-tint-300 40px,
        variables.$secondary-slate-blue-tint-200 80px
    );
    background-image: linear-gradient(
        90deg,
        variables.$secondary-slate-blue-tint-200 0px,
        variables.$secondary-slate-blue-tint-300 40px,
        variables.$secondary-slate-blue-tint-200 80px
    );
    background-size: 250px;
    -webkit-animation: shine-loading-container-items 2s infinite ease-out;
    animation: shine-loading-container-items 2s infinite ease-out;
}

@-webkit-keyframes shine-loading-image {
    0% {
        background-position: -32px;
    }
    40%,
    100% {
        background-position: 208px;
    }
}

@keyframes shine-loading-image {
    0% {
        background-position: -32px;
    }
    40%,
    100% {
        background-position: 208px;
    }
}

@-webkit-keyframes shine-loading-container-items {
    0% {
        background-position: -100px;
    }
    40%,
    100% {
        background-position: 140px;
    }
}

@keyframes shine-loading-container-items {
    0% {
        background-position: -100px;
    }
    40%,
    100% {
        background-position: 140px;
    }
}

.skeleton-line {
    height: 20px;
    margin-bottom: 10px;
    background-color: variables.$secondary-slate-blue-tint-200;
    -webkit-animation: shine-loading-image 2s infinite ease-out;
    animation: shine-loading-image 2s infinite ease-out;
}

.skeleton-table {
    width: 100%;
}

.skeleton-row {
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
}
@keyframes cell-animation {
    0% {
        background-color: variables.$secondary-slate-blue-tint-200;
    }
    50% {
        background-color: variables.$secondary-slate-blue-tint-300;
    }
    100% {
        background-color: variables.$secondary-slate-blue-tint-200;
    }
}

.skeleton-cell {
    flex: 1;
    height: 20px;
    margin-right: 10px;
    background-color: variables.$secondary-slate-blue-tint-200;
    -webkit-animation: shine-loading-image 2s infinite ease-out,
        cell-animation 2s infinite;
    animation: shine-loading-image 2s infinite ease-out,
        cell-animation 2s infinite;
}

.skeleton-cell:last-child {
    margin-right: 0;
    background-color: variables.$secondary-slate-blue-tint-200;
    -webkit-animation: shine-loading-image 2s infinite ease-out,
        cell-animation 2s infinite;
    animation: shine-loading-image 2s infinite ease-out,
        cell-animation 2s infinite;
}

.skeleton-select {
    width: 100%;
    height: 40px;
    background-color: variables.$secondary-slate-blue-tint-200;
    -webkit-animation: shine-loading-image 2s infinite ease-out;
    animation: shine-loading-image 2s infinite ease-out;
}

skeleton-text {
    height: 20px;
    width: 100%;
    background-color: variables.$secondary-slate-blue-tint-200;
    -webkit-animation: shine-loading-image 2s infinite ease-out;
    animation: shine-loading-image 2s infinite ease-out;
}

.project-skeleton-title {
    height: 40px;
    background-color: #cfcfcf;
    border-radius: 4px;
    margin-bottom: 20px;
}

.project-skeleton-card {
    height: 100px;
    background-color: #cfcfcf;
    border-radius: 4px;
    margin-bottom: 20px;
}

.project-skeleton-table {
    height: 200px;
    background-color: #cfcfcf;
    border-radius: 4px;
}
/* HTML: <div class="loader"></div> */
.loader {
    width: 35px;
    aspect-ratio: 1;
    --_g: no-repeat radial-gradient(circle closest-side, #000 90%, #0000);
    background: var(--_g) 0 0, var(--_g) 0 100%, var(--_g) 100% 100%;
    background-size: 40% 40%;
    animation: l11 1s infinite linear;
}
@keyframes l11 {
    25% {
        background-position: 100% 0, 0 100%, 100% 100%;
    }
    50% {
        background-position: 100% 0, 0 0, 100% 100%;
    }
    75% {
        background-position: 100% 0, 0 0, 0 100%;
    }
    100% {
        background-position: 100% 100%, 0 0, 0 100%;
    }
}
