@use "../../variables.scss" as variables;
.carousal-card {
    border: none;
    border-radius: 15px;
    box-shadow: 0px 4px 4px 0px #00000040;
    overflow: hidden;
    .card-favorite-icon {
        position: absolute;
        top: 10px;
        right: 10px;
    }

    .carousel-indicators button {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: #feefef;
    }

    .carousel-indicators .active {
        background-color: #ffffff;
    }

    .carousel-image {
        width: 100%;
        height: 200px;
        object-fit: cover;
    }
}

.dashboard-card {
    background-color: variables.$sidebar-hover-color !important;
    border-radius: 12px !important;
    border: none;
    .name-text {
        color: #0d171c;
        font-family: variables.$font-family-public-sans;
        font-size: 16px;
        font-style: normal;
        font-weight: variables.$font-weight-medium;
        line-height: 24px /* 150% */;
    }
    .number-text {
        color: #0d171c;
        font-family: variables.$font-family-public-sans;
        font-size: 24px;
        font-style: normal;
        font-weight: variables.$font-weight-bold;
        line-height: 30px /* 125% */;
        letter-spacing: -0.6px;
    }
}

.transparent-card {
    background-color: transparent;
    border: 2px solid #dbe0e5;
    border-radius: 12px !important;
    box-shadow: none !important;
    .name-text {
        color: #0d171c;
        font-family: variables.$font-family-public-sans;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px /* 150% */;
    }
    .number-text {
        color: #0d171c;
        font-family: variables.$font-family-public-sans;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 30px /* 125% */;
        letter-spacing: -0.6px;
    }
}

.positive-percentage {
    color: green !important;
}

.negative-percentage {
    color: red !important;
}


.summary-card {
    background-color: #ffffff;
    border-radius: 12px !important;
    border: none;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
    padding: 0.5rem;

    .summary-title {
        font-weight: 500;
        margin-bottom: 0.5rem;
        color: #1f2937;
    }

    .summary-value {
        font-weight: 700;
        color: #111827;
        line-height: 1.2;
    }
}