@use "../../variables.scss" as variables;

.image-icon {
    border-radius: 8px;
}

.integration-card-title {
    color: #121417;
    font-family: variables.$font-family-inter;
    font-size: 16px;
    font-style: normal;
    font-weight: variables.$font-weight-semibold;
    line-height: 24px /* 150% */;
}

.integration-card-subtitle {
    color: #637587;
    font-family: variables.$font-family-inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px /* 150% */;
}
