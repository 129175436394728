@use "../../variables.scss" as variables;

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: variables.$font-family-public-sans;
}

.table-responsive {
    border: 1px solid #e5e7e8;
    background-color: variables.$white;
    font-family: variables.$font-family-public-sans;
    color: variables.$primary-night;
    height: auto;
    border-radius: 12px;
    border: 1px solid variables.$border-color;
    background: variables.$white;
}

.table-header {
    background-color: transparent;
    color: variables.$primary-color-dark-blue;
    font-family: variables.$font-family-public-sans;
    font-size: 0.9em;
    font-weight: variables.$font-weight-medium;
}

.table tbody tr {
    background-color: transparent;
    font-size: 0.8em;
    line-height: 3;
}

.table th {
    border-top: none;
    background-color: transparent;
    border-bottom: 1px solid #e5e8eb;
    line-height: 3;
}

.table tr {
    border-top: none;
    background-color: transparent;
}

.page-navigator {
    background-color: transparent;
}

.previous-page {
    background-color: transparent;
    border-color: none;
    border: 0cap;
    color: variables.$primary-color-blue-gray;
    font-weight: variables.$font-weight-semibold;
    font-family: variables.$font-family-public-sans;
}

.next-page {
    background-color: transparent;
    border-color: none;
    border: 0cap;
    color: variables.$primary-color-blue-gray;
    font-weight: variables.$font-weight-semibold;
    font-family: variables.$font-family-public-sans;
}

.page-item.disabled .previous-page,
.page-item.disabled .next-page {
    background-color: transparent;
    border: none;
    .page-link {
        color: variables.$primary-night;
        font-family: variables.$font-family-public-sans;
    }
}

// Update the page-item active state styling
.page-item.active {
    .page-link {
        background-color: variables.$primary-color-blue-gray !important;
        color: variables.$white;
        font-family: variables.$font-family-public-sans;
        border: none;
    }
}

// Update page number styling
.page-number {
    background-color: variables.$secondary-color-blue-gray-30;
    color: variables.$secondary-color-blue-gray-40;
    font-family: variables.$font-family-public-sans;
    border: none;

    &:hover,
    &:focus {
        background-color: variables.$primary-color-blue-gray !important;
        color: variables.$white;
    }

    &:active {
        background-color: variables.$primary-color-blue-gray !important;
        color: variables.$white;
        box-shadow: none;
    }
}

.custom-control-input ::before {
    background-color: variables.$secondary-slate-blue-tint-100;
    width: 25px;
    height: 25px;
}

.table-row-border {
    border-bottom: 1px solid #e5e8eb;
}

tr button {
    background: none;
    border: none;
    padding: 0.25rem 0.5rem;
    cursor: pointer;
    color: variables.$primary-color-blue-gray;

    &:hover {
        color: variables.$primary-color-dark-blue;
    }
}

.expanded-row-content {
    padding: 1rem;
}

.expanded-info {
    margin: 0;

    .expanded-table {
        width: 100%;
        border-collapse: collapse;

        td {
            padding: 0.5rem 1rem;
            border: none;
            font-size: 0.875rem;
            line-height: 1.5;
            text-align: left;

            .key-column {
                font-weight: variables.$font-weight-medium;
                color: variables.$primary-color-dark-blue;
                font-size: 0.875rem;
            }

            .value-column {
                color: variables.$primary-night;
                font-size: 0.875rem;
            }
        }
    }
}

@media screen and (max-width: 600px) {
    table {
        border: 0;
    }

    .table-responsive,
    .sort-wrapper {
        display: flex;
        gap: 20px;
    }

    table caption {
        font-size: 1.3em;
    }

    table thead {
        border: none;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
    }

    table tr {
        border-bottom: 3px solid #ddd;
        display: block;
        margin-bottom: 0.625em;
    }

    table td {
        border-bottom: 1px solid #ddd;
        display: block;
        font-size: 0.8em;
        text-align: right;
        padding-left: 35px;
        position: relative;

        &::before {
            position: absolute;
            left: 1rem;
            // width: calc(50% - 1rem);
        }
    }

    table td::before {
        /*
      * aria-label has no advantage, it won't be read inside a table
      content: attr(aria-label);
      */
        content: attr(data-label);
        float: left;
        font-weight: bold;
        text-transform: uppercase;
    }

    table td:last-child {
        border-bottom: 0;
    }

    // Add these new styles
    button[aria-label="Toggle Row Expanded"] {
        display: inline-block;
        position: absolute;
        left: 10px;
        top: 50%;
        transform: translateY(-50%);
    }

    // Style for expanded content on mobile
    tr[data-expanded="true"] {
        td {
            position: relative;
            padding-left: 40px !important;
        }
    }

    // Ensure expanded content takes full width
    td[colspan] {
        width: 100%;
        text-align: left;
        padding: 1rem !important;
    }

    // Fix expand/collapse button visibility
    button {
        opacity: 1;
        visibility: visible;
        position: relative;
        z-index: 2;
    }
}
