@use "../../variables.scss" as variables;

/** Common for all project related Page **/

.project-container {
    // background-color: variables.$white;
    overflow: hidden;
}

.subtitle {
    font-family: variables.$font-family-public-sans;
    font-weight: variables.$font-weight-normal;
    color: variables.$secondary-night-tint-400;
    font-size: 0.8em;
}

/** Project Details Page **/

.subtitle span {
    font-family: variables.$font-family-public-sans;
    font-weight: variables.$font-weight-normal;
    color: variables.$primary-color-blue;
    font-size: 0.8em;
    background-color: variables.$secondary-slate-blue-tint-200;
    display: inline;
    padding: 4px;
}

.edit span {
    font-family: variables.$font-family-public-sans;
    font-weight: variables.$font-weight-semibold;
    color: variables.$primary-color-blue;
    font-size: 1em;
    display: inline;
    padding: 2px;
}

.dashboard-box {
    background-color: variables.$secondary-slate-blue-tint-200;
    font-weight: variables.$font-weight-semibold;
    font-size: 1em;
    font-family: variables.$font-family-public-sans;
    color: variables.$secondary-night-tint-400;
}

/** Configuration Tabs **/

.divider-line {
    border: 1px solid variables.$secondary-night-tint-200;
    margin-top: 20px;
    margin-bottom: 20px;
}

.node-users {
    font-weight: variables.$font-weight-normal;
    font-size: 1em;
    font-family: variables.$font-family-public-sans;
    color: variables.$primary-color-blue;
}

/** Dashboard Tabs **/

.asset-trace-card {
    border: 1px solid variables.$secondary-argentinian-blue-shade-100;
    padding: 10px;
    margin-right: 10px;
}

.asset-trace-details {
    background-color: whitesmoke;
    height: 100%;
    width: 100%;
    padding: 20px;
}

.field-group-container {
    border: 1px solid #cececf;
    margin-top: 20px;
}

.field-group-action-remove {
    cursor: pointer;
    color: variables.$primary-color-dark-gray-50;

    &:hover {
        opacity: 0.75;
    }
}

/** CreateProject Component **/

.page-title {
    font-size: 2em;
    color: variables.$primary-color-blue;
    margin-bottom: 20px;
}

.section-title {
    font-size: 1.5em;
    color: variables.$secondary-night-tint-400;
    margin-bottom: 20px;
}

.create-project-card {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin-top: 20px;
}
