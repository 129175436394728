@use '../../../pages/customer/Trace/trace-app-variables.scss' as variables;

.footer-container {
  min-height: 60px;
  background-color: variables.$primary-cream;
  padding: 0px !important;
  @include variables.responsive-container;
}

.container-fluid{
  padding: 0px !important;
  margin: 0px !important;
}

.footer-logo {
  width: 110px;
  height: auto;
  opacity: 1;
  transition: opacity variables.$transition-duration variables.$transition-timing;

  @include variables.tablet-only {
    width: 130px;
  }

  @include variables.desktop-only {
    width: 150px;
  }

  &:hover {
    opacity: 0.8;
  }
}

.powered-by-text {
  font-family: variables.$font-family-primary;
  font-weight: variables.$font-weight-medium;
  letter-spacing: -0.05em;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: variables.$primary-dark;
  @include variables.responsive-font-size('xs');
}