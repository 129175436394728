@use "../../variables.scss" as variables;

.form-control {
    width: 30%;
    font-style: normal;
    font-family: variables.$font-family-public-sans;
    font-size: 1em;
    border-radius: 12px !important;
    font-weight: 500;
    background-color: transparent !important;
    border: 1px solid variables.$secondary-night-tint-400;
    &:hover {
        color: variables.$primary-color-blue;
        font-style: normal;
        font-family: variables.$font-family-public-sans;
    }
    &:focus {
        color: variables.$primary-color-blue;
        font-family: variables.$font-family-public-sans;
        font-style: normal;
    }
    &:disabled {
        color: variables.$secondary-night-tint-200;
        font-family: variables.$font-family-public-sans;
        font-style: normal;
    }
}

.form-control::placeholder {
    color: variables.$secondary-night-tint-200 !important;
}

.error {
    border: 2px solid variables.$white;
}

.error-label {
    color: variables.$danger;
}

.invalid-feedback {
    color: red;
    font-size: 0.875em;
    font-family: variables.$font-family-public-sans;
    font-weight: variables.$font-weight-semibold;
    margin-top: 2px;
    font-size: 0.75em;
}

.form-label {
    font-family: variables.$font-family-inter;
    font-weight: variables.$font-weight-medium;
    color: variables.$secondary-night-tint-400;
    font-size: 1em;
}

.select {
    position: relative;
    .form-select {
        color: variables.$select-dropdown-color;
        font-style: normal;
        font-family: variables.$font-family-public-sans;
        border-radius: 12px;
        font-weight: variables.$font-weight-normal;
        background-color: transparent;
        border: 1px solid variables.$secondary-night-tint-300;

        &:focus {
            background-color: transparent;
            color: variables.$primary-night;
            font-family: variables.$font-family-public-sans;
            font-style: normal;
        }

        &:disabled {
            color: variables.$secondary-night-tint-200;
            font-family: variables.$font-family-public-sans;
            font-style: normal;
            background-color: transparent !important;
        }
    }

    .form-select option {
        background-color: variables.$white;
        color: variables.$primary-color-dark-gray;
        font-family: variables.$font-family-public-sans;
        font-weight: variables.$font-weight-normal;
        font-size: 1em;

        &:hover,
        &:focus {
            background-color: variables.$primary-color-blue-gray;
            color: variables.$white;
        }
    }

    // &::after {
    //     content: "";
    //     background: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50"%3E%3Cpolyline points="15,20 25,30 35,20" stroke="%23637587" stroke-width="4" fill="none" /%3E%3C/svg%3E')
    //         no-repeat;
    //     background-size: contain;
    //     width: 20px;
    //     height: 20px;
    //     position: absolute;
    //     right: 10px;
    //     top: 50%;
    //     transform: translateY(-50%);
    //     pointer-events: none;
    // }
}

.form-check-label {
    font-family: variables.$font-family-public-sans;
    font-weight: variables.$font-weight-normal;
    font-size: 1em;
}

.add-container {
    margin-top: 10px;
    border: 1px solid #cececf;
}

.add-header {
    font-family: variables.$font-family-public-sans;
    font-weight: variables.$font-weight-normal;
    font-size: 1em;
    color: variables.$secondary-night-tint-300;
}

.add-close {
    cursor: pointer;
}

.add-another-node {
    font-weight: variables.$font-weight-semibold;
    font-size: 1em;
    font-family: variables.$font-family-public-sans;
    color: variables.$primary-color-blue;
    cursor: pointer;
}

.required-field {
    color: variables.$secondary-night-tint-500;
}

.custom-file-upload {
    padding: 10px;
    border: 1px solid #ccc;
    display: inline-block;
    cursor: pointer;
    /* Add more styles as needed */
}

.dropzone {
    border: 2px dashed variables.$border-color;
    background-color: transparent;
    padding: 20px;
    cursor: pointer;
    border-radius: 12px;
}

.search-input {
    position: relative;
}

.search-icon {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
    color: variables.$secondary-night-tint-500 !important;
    font-size: 0.9rem;
    opacity: 0.5;
}

.search-input input {
    padding-left: 30px;
    background-color: transparent;
    font-size: 0.9rem;
    color: variables.$secondary-night-tint-500;
}

.search-input input::placeholder {
    color: variables.$secondary-night-tint-500 !important;
    font-weight: variables.$font-weight-normal;
    font-size: 1em;
    font-family: variables.$font-family-public-sans !important;
    opacity: 0.5;
}

.upload-icon {
    font-size: 2.5em;
    color: variables.$secondary-night-tint-300;
}

.support-text-image {
    font-family: variables.$font-family-public-sans;
    font-weight: variables.$font-weight-normal;
    font-size: 0.7em;
    color: variables.$secondary-night-tint-300;
}

.image-text {
    font-family: variables.$font-family-public-sans;
    font-weight: variables.$font-weight-semibold;
}

.drag-drop {
    background: transparent;
    border: 2px dashed variables.$border-color;
    border-radius: 8px;
}

.document-uploader {
    padding: 20px;
    position: relative;
    border-radius: 8px;

    .upload-info {
        svg {
            font-size: 36px;
            margin-right: 1rem;
        }

        div {
            p {
                margin: 0;
                font-size: 16px;
            }

            p:first-child {
                font-weight: bold;
            }
        }
    }

    .file-list {
        gap: 0.5rem;
        width: 100%;
        // height: 30vh;

        &__container {
            width: 100%;
            height: 100%;
            overflow: auto;
        }
    }

    .file-item {
        padding: 0.5rem;
        border: 1px solid variables.$border-color;
        border-radius: 8px;

        .file-info {
            gap: 0.25rem;

            p {
                margin: 0;
                font-size: 14px;
                color: #333;
            }
        }

        .file-actions {
            cursor: pointer;

            svg {
                font-size: 18px;
                color: #888;
            }

            &:hover {
                svg {
                    color: #d44;
                }
            }
        }
    }

    .browse-btn {
        color: variables.$primary-color-blue !important;
        &:hover {
            background-color: transparent;
        }
    }

    .success-file {
        color: #6dc24b;

        p {
            margin: 0;
            font-size: 14px;
            font-weight: bold;
        }
    }

    input[type="file"] {
        display: none;
    }
}

.color-picker {
    display: inline-flex;
    align-items: center;
    padding: 8px 12px;
    border: 1px solid variables.$form-border-color;
    border-radius: 4px;
    appearance: none;
    background-image: none !important;
    color: variables.$select-dropdown-color;
    font-style: normal;
    font-family: variables.$font-family-public-sans;
    border-radius: 12px;
    font-weight: variables.$font-weight-normal;
    background-color: transparent;
    line-height: 2;
    border: 1px solid #cececf;
    .color-input {
        -webkit-appearance: none;
        appearance: none;
        margin-right: 8px;
        border: none;
        width: auto;
        height: auto;
        cursor: pointer;
        background: none;
        outline: none;

        &::-webkit-color-swatch-wrapper {
            padding: 0;
            width: 25px;
            height: 19px;
        }

        &::-webkit-color-swatch {
            border: 1px solid #bfc9d9;
            border-radius: 0;
            padding: 0;
        }
    }

    .text-input {
        border: none;
        width: 100%;
        font-size: 1rem;
        color: variables.$select-dropdown-color;
        pointer-events: none;
        background-color: transparent;
    }
}

.searchable-select {
    position: relative;
    width: 100%;
}

.options-list {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    max-height: 200px;
    overflow-y: auto;
    background-color: #f7fafa;
    border: 1px solid #ccc;
    border-top: none;
    z-index: 1000;
    list-style: none;
    padding: 0;
    margin: 0;
    border-radius: 12px;
}

.options-list li {
    padding: 8px 12px;
    cursor: pointer;
}

.options-list li:hover {
    background-color: #f0f0f0;
}

.cursor-pointer {
    cursor: pointer;
    &:hover {
        background-color: #f8f9fa;
    }
}

.form-switch {
    .form-check-input {
        cursor: pointer;
        &:checked {
            background-color: variables.$primary-color-blue;
            border-color: variables.$primary-color-blue;
        }
        &:disabled {
            opacity: 0.6;
            cursor: not-allowed;
        }
    }

    .form-check-label {
        cursor: pointer;
        color: variables.$secondary-night-tint-400;
    }
}

.toggle-switch {
    display: flex;
    align-items: center;
}

.toggle-switch-checkbox {
    display: none;
}

.toggle-switch-label {
    display: block;
    width: 50px;
    height: 24px;
    position: relative;
    cursor: pointer;
    background-color: #ccc;
    border-radius: 24px;
    transition: background-color 0.2s;
}

.toggle-switch-label .toggle-switch-inner {
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 24px;
    background-color: #ccc;
    transition: background-color 0.2s;
}

.toggle-switch-label .toggle-switch-switch {
    display: block;
    width: 22px;
    height: 22px;
    position: absolute;
    top: 1px;
    left: 1px;
    border-radius: 50%;
    background-color: #fff;
    transition: left 0.2s;
}

.toggle-switch-checkbox:checked + .toggle-switch-label .toggle-switch-inner {
    background-color: variables.$primary-color-blue;
}

.toggle-switch-checkbox:checked + .toggle-switch-label .toggle-switch-switch {
    left: 27px;
}

.toggle-switch-text {
    margin-left: 10px;
}
