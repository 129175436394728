@use "../../variables.scss" as variables;

.toast-success {
    background-color: variables.$success;
    color: #fff;
}

.toast-error {
    background-color: variables.$danger;
    color: #fff;
}
