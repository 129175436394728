@use "../../variables.scss" as variables;

.selectableTypography {
    cursor: pointer;
}

.selectableTypography.selected {
    border-left: 3px solid variables.$primary-color-blue;
    padding-left: 10px;
}

.divider-line {
    border-bottom: 2px solid variables.$secondary-night-tint-200;
}

.onboarding-description {
    font-family: variables.$font-family-manrope !important;
}
