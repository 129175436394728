@use "../../../variables.scss" as variables;

.project-card {
    border-radius: 0px;
    border: 1px solid variables.$secondary-slate-blue-tint-300;
    box-shadow: -6px 12px 32px #0b0b0f0a;
    background-color: variables.$white;

    .card-img-top {
        object-position: center;
        // width: 20%;
        border-radius: 50%;
        // background-color: variables.$secondary-color-blue-30;
    }

    .card-title {
        font-family: variables.$font-family-public-sans;
        font-weight: variables.$font-weight-semibold;
        color: variables.$primary-night;
        font-size: 1.2em;
    }

    .card-subtitle span {
        font-family: variables.$font-family-public-sans;
        font-weight: variables.$font-weight-normal;
        color: variables.$primary-color-blue;
        font-size: 0.8em;
        background-color: variables.$secondary-color-blue-gray-50;
        display: inline;
        padding: 5px;
    }

    .card-link {
        font-family: variables.$font-family-public-sans;
        font-weight: variables.$font-weight-semibold;
        color: variables.$primary-color-blue;
        font-size: 1em;

        &:hover {
            cursor: pointer;
        }
    }

    .circular-border {
        /* Center the content */
        align-items: center;
        display: flex;
        justify-content: center;

        /* Colors */
        border-radius: 50%;
        background-color: variables.$secondary-color-blue-gray-50;
        color: #fff;

        /* Rounded border */
        border-radius: 50%;
        height: 2.5em;
        width: 2.5em;
    }

    .card-text {
        font-family: variables.$font-family-public-sans;
        font-weight: variables.$font-weight-normal;
        color: variables.$secondary-night-tint-500;
        font-size: 0.8em;
    }
}
