/*
* Mint Page
*/
@use "./../../../../variables.scss" as variables;

.mint-container {
    background: variables.$secondary-slate-blue-tint-500;
    padding-top: 90px;
}

.sling-logo {
    border: 1px solid variables.$secondary-night-tint-200;
    border-radius: 92px;
    opacity: 1;
    width: 184px;
    height: 184px;
    overflow: hidden;
}
.initial-avatar {
    /* Center the content */
    align-items: center;
    display: flex;
    justify-content: center;

    /* Colors */
    background-color: variables.$information-tint-200;
    color: #fff;

    /* Rounded border */
    border-radius: 50%;
    height: 2.5rem;
    width: 2.5rem;
}

.text-card {
    background-color: variables.$secondary-slate-blue-tint-200;
    border: 0;
    border-radius: 0;
    width: 100%; // Adjusted to full width
    opacity: 1;
}

.para-1 {
    color: variables.$secondary-night-tint-500;
    font-weight: variables.$font-weight-normal;
    font-size: 16px;
    opacity: 1;
}

.progressbar {
    counter-reset: step;
}

.progressbar li {
    list-style-type: none;
    float: left;
    width: 33.33%;
    position: relative;
}

.progressbar li:before {
    content: counter(step);
    counter-increment: step;
    width: 30px;
    height: 30px;
    line-height: 30px;
    border: 1.5px solid variables.$information;
    display: block;
    text-align: center;
    background-color: variables.$secondary-slate-blue-tint-200;
    border-radius: 50%;
    color: variables.$information;
    position: relative; /* Added position: relative */
    z-index: 1; /* Added z-index: 1 */
}

.progressbar li:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    background: repeating-linear-gradient(
        90deg,
        variables.$information,
        variables.$information 2px,
        transparent 3px,
        transparent 5px
    );
    top: 15px;
    left: -100%;
    z-index: 0;
}
.progressbar li:first-child:after {
    content: none;
}

.logo-text {
    display: flex;
    width: 100%;
    height: 100%;
    background-color: variables.$primary-slate-blue;
    color: antiquewhite;
    font-size: 5em;
    align-items: center;
    justify-content: center;
}
