.pricing-component {
    .pricing-card {
        position: relative;
        border-radius: 12px;
        overflow: hidden;
        transition: all 0.3s ease;
        height: 100%;
        box-shadow: 0 4px 16px rgba(0, 0, 0, 0.08);
        border: 1px solid rgba(0, 0, 0, 0.05);
        padding: 8px;
    }

    .card-body {
        padding: 1.5rem;
    }

    .current-plan {
        border: 2px solid #0275d8;
        box-shadow: 0 0.5rem 1.5rem rgba(2, 117, 216, 0.2) !important;
        transform: scale(1.02);
    }

    .blocked-plan {
        opacity: 0.75;
    }

    .plan-badge {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        background: #f8d7da;
        color: #721c24;
        padding: 4px 8px;
        font-size: 0.8rem;
        font-weight: bold;
        text-align: center;
        letter-spacing: 0.5px;
    }

    .pricing-display {
        display: flex;
        align-items: baseline;
        flex-wrap: nowrap;

        .plan-price {
            font-size: 2rem;
            font-weight: 700;
            color: #333;
            margin-right: 4px;
        }

        .pricing-period {
            color: #6c757d;
            margin-left: 2px;
        }
    }

    .manage-link {
        background: none;
        border: none;
        padding: 0;
        color: #0275d8;
        font-weight: 500;
        transition: all 0.2s;
        font-size: 0.9rem;
        text-decoration: none;
        display: inline-flex;
        align-items: center;
        position: relative;

        &::after {
            content: "";
            position: absolute;
            width: 100%;
            height: 1px;
            bottom: -2px;
            left: 0;
            background-color: #0275d8;
            transform: scaleX(0);
            transform-origin: bottom right;
            transition: transform 0.3s ease-out;
        }

        &:hover {
            color: #0056b3;

            &::after {
                transform: scaleX(1);
                transform-origin: bottom left;
            }
        }

        &:active {
            color: #004085;
        }

        &:disabled {
            opacity: 0.65;
            cursor: not-allowed;

            &::after {
                display: none;
            }
        }
    }

    ul {
        margin-left: 10px;

        li {
            margin-bottom: 10px;

            .bi-check-circle {
                color: #0275d8;
            }
        }
    }
}
