/*
* Card
*/

@use "../../../../variables.scss" as variables;
.card {
    box-shadow: -6px 12px 32px #0b0b0f0a;
    border-radius: 0%;
}

.card-icon {
    border-radius: 0%;
}

.card-title {
    font-size: 20px;
}

.card-text {
    color: variables.$secondary-night-tint-500;
    font-size: 16px;
}

.change-text {
    background-color: variables.$success-tint-100;
    font-size: 14px;
    color: variables.$success;
    font-weight: variables.$font-weight-normal;
}

.card-subtitle {
    font-size: 16px;
    font-weight: variables.$font-weight-normal;
}

.customer-dashboard-card {
    background-color: transparent !important;
    border: 2px solid #dbe0e5 !important;
    border-radius: 12px !important;
    box-shadow: none !important;
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;

    .name-text {
        color: #4f7a96;
        font-family: variables.$font-family-inter;
        font-size: 16px;
        font-style: normal;
        font-weight: variables.$font-weight-medium;
        line-height: 24px /* 150% */;
    }
    .number-text {
        color: #0d171c;
        font-family: variables.$font-family-inter;
        font-size: 24px;
        font-style: normal;
        font-weight: variables.$font-weight-bold;
        line-height: 30px /* 125% */;
        letter-spacing: -0.6px;
    }
}

.customer-transparent-card {
    cursor: pointer;
    background: transparent !important;
    border-radius: 10px;
    width: 100%;
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;

    &:hover {
        transform: scale(1);
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.12);
    }
    .card-image {
        width: 50px;
        height: 50px;
        // border-radius: 5px;
        object-fit: cover;
    }

    .card-amount {
        margin-left: auto;
        font-size: 16px;
    }
}
