/*
* Button
*/

@use "../../variables.scss" as variables;

.btn {
    padding: 10px 20px !important;
    font-family: variables.$font-family-public-sans !important;
    font-weight: variables.$font-weight-bold !important;
    color: variables.$white !important;
    font-size: 0.875em !important;
}

.btn-sm {
    /* Styles for small buttons */
    font-size: 0.9em !important;
    display: flex !important;
    padding: 5px 10px !important;
    align-items: center !important;
    justify-content: center !important;
}

.btn-lg {
    /* Styles for large buttons */
    font-size: 0.9em !important;
    display: flex !important;
    padding: 15px 30px !important;
    align-items: center !important;
    justify-content: center !important;
}
.btn-primary {
    display: flex;
    padding: 0px 16px;
    justify-content: center;
    align-items: center;
    border-radius: 14px !important;
    background: variables.$primary-color-blue !important;
    color: variables.$white !important;
    &:hover {
        background-color: variables.$primary-button-hover-color !important;
        color: variables.$white !important;
    }
    &:focus {
        color: variables.$white !important;
        background-color: variables.$primary-button-focus-color !important;
    }
    &:disabled {
        background-color: variables.$button-disabled-color !important;
        opacity: 0.5 !important;
        color: variables.$white !important;
    }
}

.btn-secondary {
    background-color: variables.$secondary-color-blue-gray-40 !important;
    color: variables.$primary-color-blue-gray !important;
    border: 0px !important;
    border-radius: 12px !important;
    font-weight: variables.$font-weight-bold !important;
    &:hover {
        background-color: variables.$secondary-color-blue-gray-10 !important;
        color: variables.$secondary-color-blue-gray-40 !important;
    }
    &:focus {
        background-color: variables.$secondary-color-blue-gray-10 !important;
        color: variables.$secondary-color-blue-gray-40 !important;
    }
}
.btn-outline-primary {
    color: variables.$primary-color-blue !important;
    font-family: variables.$font-family-public-sans !important;
    font-weight: variables.$font-weight-normal !important;
    background-color: white !important;
    border: 1px solid variables.$primary-color-blue !important;
    border-radius: 0% !important;
    &:hover {
        background-color: variables.$primary-button-hover-color !important;
        color: variables.$white !important;
    }
    &:focus {
        background-color: variables.$primary-button-focus-color !important;
        color: variables.$white !important;
    }
}

.btn-ghost-primary {
    color: variables.$primary-color-blue !important;
    background-color: transparent !important;
    border: 0px !important;
    &:hover {
        color: variables.$primary-button-hover-color !important;
        background-color: transparent !important;
    }
    &:focus {
        color: variables.$primary-button-focus-color !important;
        background-color: transparent !important;
    }
}

.dropdown-toggle::after {
    display: inline-block !important;
    width: 0.4em !important;
    height: 0.4em !important;
    margin: -0.4em 0 0 0.4em !important;
    vertical-align: middle !important;
    border: 0.4em solid !important;
    border-width: 0 0.15em 0.15em 0 !important;
    transform: rotateZ(45deg) !important;
}

// .btn-ghost-Primary {
//     color: variables.$primary-slate-blue !important;
//     background-color: transparent !important;
//     border: 0px !important;
//     &:hover {
//         color: variables.$secondary-slate-blue-shade-200 !important;
//         background-color: transparent !important;
//     }
//     &:focus {
//         color: variables.$secondary-slate-blue-shade-400 !important;
//         background-color: transparent !important;
//     }
// }

.btn-dropdown {
    color: variables.$white !important;
    background-color: variables.$primary-color-blue !important;
    border: 0px !important;
    &:hover {
        color: variables.$secondary-color-blue-40 !important;
        background-color: variables.$primary-button-hover-color !important;
    }
    &:focus {
        color: variables.$secondary-color-blue-40 !important;
        background-color: variables.$primary-button-focus-color !important;
    }
}

.btn-customer-primary {
    color: variables.$white !important;
    background: var(--customer-portal-primary-color) !important;
    border: 0px !important;
    border-radius: 12px !important;
    &:hover {
        background: var(--customer-portal-primary-color) !important;
        filter: brightness(90%) !important;
        color: variables.$white !important;
    }
    &:focus {
        background: var(--customer-portal-primary-color) !important;
        filter: brightness(90%) !important;
        color: variables.$white !important;
    }
}

.btn-customer-secondary {
    background: var(--customer-portal-secondary-color) !important;
    color: variables.$white !important;
    border: 0px !important;
    border-radius: 12px !important;
    font-weight: variables.$font-weight-bold !important;
    &:hover {
        background: var(--customer-portal-secondary-color) !important;
        filter: brightness(90%) !important;
        color: variables.$white !important;
    }
    &:focus {
        background: var(--customer-portal-secondary-color) !important;
        filter: brightness(90%) !important;
        color: variables.$white !important;
    }
}

