@use "../../../../variables.scss" as variables;

// .customer-tab {
.nav-nav-tabs {
    border-bottom: 2px solid #ddd !important;
}

.nav-nav-tabs > li {
    margin-bottom: -1px !important; // Remove the default bottom margin
}

.nav-nav-tabs > li > a {
    border: none !important;
    color: #9d9d9f !important; // Default color for unselected tabs
    background: transparent !important;
    position: relative !important;
}

.nav-nav-tabs > li.active > a,
.nav-nav-tabs > li > a:hover {
    border: none !important;
    color: var(--customer-portal-primary-color) !important;
    background: transparent !important;
}

.nav-nav-tabs > li > a::after {
    content: "" !important;
    position: absolute !important;
    width: 100% !important;
    height: 2px !important; // Height of the line below the tab
    left: 0 !important;
    bottom: -2px !important; // Adjust the position to hide the default border
    background-color: var(
        --customer-portal-primary-color
    ) !important; // Color of the line below the tab
    transform: scaleX(0) !important; // Initial scale to hide the line
    transition: transform 0.3s ease !important;
}

.nav-nav-tabs > li.active > a::after,
.nav-nav-tabs > li:hover > a::after {
    transform: scaleX(
        1
    ) !important; // Show the line on hover and for the selected tab
}

// Ensure that the line is also shown when the tab is focused (e.g., via keyboard navigation)
.nav-nav-tabs > li.active > a:focus::after,
.nav-nav-tabs > li:focus > a::after {
    transform: scaleX(1) !important;
}
