@use "../../../../variables.scss" as variables;

.dashboard-container {
    padding-top: 70px;
    padding-bottom: 70px;
}

.filter {
    font-size: 16px;
    font-weight: variables.$font-weight-semibold;
    color: variables.$primary-slate-blue;
}

.customer-dashboard-card {
    border-radius: 12px;
    background-color: transparent !important;
    box-shadow: none !important;
}
