@import url("https://fonts.googleapis.com/css2?family=Albert+Sans:wght@500&family=Marcellus&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Manrope:wght@200..800&family=Public+Sans:ital,wght@0,100..900;1,100..900&family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap");
// Color
$primary-slate-blue: #635fec;
$primary-argentinian-blue: #5cadff;
$primary-night: #0b0b0f;
$ghost-white: #f7f7ff;
$alice-blue: #f7fbff;
$background-color: rgb(243 244 246 / 1);

$secondary-slate-blue-tint-100: #f7f7fe;
$secondary-slate-blue-tint-200: #efeffd;
$secondary-slate-blue-tint-300: #e0dffb;
$secondary-slate-blue-tint-400: #c0bff7;
$secondary-slate-blue-tint-500: #a19ff3;
$secondary-slate-blue-tint-600: #827fef;

$secondary-argentinian-blue-tint-100: #f8fcff;
$secondary-argentinian-blue-tint-200: #f0f8ff;
$secondary-argentinian-blue-tint-300: #e2f1ff;
$secondary-argentinian-blue-tint-400: #c6e3ff;
$secondary-argentinian-blue-tint-500: #a9d4ff;
$secondary-argentinian-blue-tint-600: #8cc6ff;

$secondary-night-tint-100: #f2f2f2;
$secondary-night-tint-200: #e6e6e6;
$secondary-night-tint-300: #cececf;
$secondary-night-tint-400: #9d9d9f;
$secondary-night-tint-500: #6c6c6f;
$secondary-night-tint-600: #3b3b3f;

$secondary-slate-blue-shade-100: #5d5ae1;
$secondary-slate-blue-shade-200: #5955d4;
$secondary-slate-blue-shade-300: #4f4cbc;
$secondary-slate-blue-shade-400: #3b398d;
$secondary-slate-blue-shade-500: #27265e;
$secondary-slate-blue-shade-600: #14132f;

$secondary-argentinian-blue-shade-100: #6aaef2;
$secondary-argentinian-blue-shade-200: #65a6e6;
$secondary-argentinian-blue-shade-300: #5993cc;
$secondary-argentinian-blue-shade-400: #436e99;
$secondary-argentinian-blue-shade-500: #2d4a66;
$secondary-argentinian-blue-shade-600: #162533;

$success: #01cb8e;
$success-tint-100: #ccf4e8;
$success-tint-200: #99ead2;
$success-tint-300: #66e0bb;
$success-tint-400: #33d5a4;

$danger: #ff4d4f;
$danger-tint-100: #ffd5d5;
$danger-tint-200: #ffabab;
$danger-tint-300: #ff8182;
$danger-tint-400: #ff5858;

$information: #70b8ff;
$information-tint-100: #e2f1ff;
$information-tint-200: #c6e3ff;
$information-tint-300: #a8d4ff;
$information-tint-400: #8bc6ff;

$alert: #ffb302;
$alert-tint-100: #fff0cc;
$alert-tint-200: #ffe199;
$alert-tint-300: #ffd167;
$alert-tint-400: #ffc234;

$light-blue: #a8d4ff;
$light-purple: #a19ff3;
$light-green: #66e0bb;
$light-red: #ff8182;
$yellow: #ffd167;
$light-brown: #ac9a81;
$blue: #7291e5;
$light-purple: #df8fdf;
$light-orange: #efb37f;
$light-green: #b5e69a;
$white: #f7fafa;

// Typography
$font-family-display: "Marcellus", serif;
$font-family-primary: "Albert Sans", sans-serif;
$font-family-heading: $font-family-display;

$font-weight-lighter: lighter !default;
$font-weight-light: 300 !default;
$font-weight-normal: 400 !default;
$font-weight-medium: 500 !default;
$font-weight-semibold: 600 !default;
$font-weight-bold: 700 !default;
$font-weight-bolder: bolder !default;

h1,
h2,
h3,
h4 {
    font-family: $font-family-primary;
    font-weight: $font-weight-bold;
}

$form-check-input-focus-border: $primary-slate-blue;

//Table

// $table-striped-color: $secondary-argentinian-blue-tint-200;
$table-striped-bg-factor: 0.05;
$table-striped-bg: $secondary-slate-blue-tint-100;
$table-striped-order: even;
$table-striped-columns-order: odd;

// Colors
// Primary Color 1 - Blue
$primary-color-blue: #1c8ad1;
$primary-color-blue-gray: #4f7a96;

$secondary-color-blue-10: #4ba9e3;
$secondary-color-blue-20: #7ac7f5;
$secondary-color-blue-30: #a9e6ff;
$secondary-color-blue-40: #d8f5ff;
$secondary-color-blue-50: #ffffff;

$secondary-color-blue-gray-10: #6b8fa8;
$secondary-color-blue-gray-20: #87a4ba;
$secondary-color-blue-gray-30: #a3b9cc;
$secondary-color-blue-gray-40: #bfd0de;
$secondary-color-blue-gray-50: #dbe5f0;

$primary-color-dark-blue: #0d171c;
$primary-color-dark-blue-10: #1e2930;
$primary-color-dark-blue-20: #2f3b45;
$primary-color-dark-blue-30: #40505a;
$primary-color-dark-blue-40: #51646f;
$primary-color-dark-blue-50: #627884;

$primary-color-dark-gray: #121417;
$primary-color-dark-gray-10: #232a2e;
$primary-color-dark-gray-20: #343d43;
$primary-color-dark-gray-30: #454f58;
$primary-color-dark-gray-40: #56626d;
$primary-color-dark-gray-50: #677782;

$primary-button-hover-color: #177dbb;
$primary-button-focus-color: #2992d4;
$button-disabled-color: #42699a;

$navbar-bottom-color: #e5e8eb;
$sidebar-hover-color: #e8edf2;
$select-dropdown-color: #637587;
$border-color: #d1dee5;
$secondary-button-color: #f0f2f5;
$form-border-color: #bfc9d9;

// Typography
$font-family-public-sans: "Public Sans";
$font-family-inter: "Inter", sans-serif;
$font-family-work-sans: "Work Sans";
$font-family-manrope: "Manrope", sans-serif;

// Customer portal
:root {
    --customer-portal-primary-color: #1c8ad1;
    --customer-portal-secondary-color: #4f7a96;
    --customer-portal-nav-bar-color: #ffffff;
    --customer-portal-logo: url(https://www.kadencewp.com/wp-content/uploads/2020/10/alogo-4.png);
}

// tradeFinance
$table-status-background-color: #ededed;
$muted-text-color: #6b6b6b;
$trafefi-primary-color: #ededed;
$trafefi-secondary-color: #f7f7f7;
$trafefi-primary-night: #0b0b0f;
$table-apy-color: #6b6b6b;


// Responsive Typography System
:root {
  // Base sizes
  --font-xs: clamp(0.75rem, 0.7rem + 0.25vw, 0.875rem);    // 12px - 14px
  --font-sm: clamp(0.875rem, 0.8rem + 0.375vw, 1rem);      // 14px - 16px
  --font-base: clamp(1rem, 0.9375rem + 0.375vw, 1.125rem); // 16px - 18px
  --font-lg: clamp(1.125rem, 1rem + 0.625vw, 1.25rem);     // 18px - 20px
  --font-xl: clamp(1.25rem, 1.125rem + 0.75vw, 1.5rem);    // 20px - 24px
  --font-2xl: clamp(1.5rem, 1.375rem + 0.875vw, 1.875rem); // 24px - 30px
  --font-3xl: clamp(1.875rem, 1.75rem + 1vw, 2.25rem);     // 30px - 36px
  --font-4xl: clamp(2.25rem, 2rem + 1.25vw, 3rem);         // 36px - 48px
}

// Utility Classes
.text-xs { font-size: var(--font-xs); }
.text-sm { font-size: var(--font-sm); }
.text-base { font-size: var(--font-base); }
.text-lg { font-size: var(--font-lg); }
.text-xl { font-size: var(--font-xl); }
.text-2xl { font-size: var(--font-2xl); }
.text-3xl { font-size: var(--font-3xl); }
.text-4xl { font-size: var(--font-4xl); }