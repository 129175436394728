.batch-dropdown {
  position: relative;
  width: 100%;
}

.batch-dropdown-input {
  width: 100%;
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  outline: none;
}

.batch-dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-top: 4px;
  max-height: 200px;
  overflow-y: auto;
  z-index: 1000;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.batch-dropdown-item {
  padding: 8px 12px;
  cursor: pointer;
}

.batch-dropdown-item:hover {
  background-color: #f5f5f5;
}

.create-new {
  color: #0066cc;
  font-style: italic;
}

.input-search-dropdown {
  position: relative;
  width: 100%;

  &__input-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    border: 1px solid #ddd;
    border-radius: 6px;
    transition: all 0.2s ease;

    &:hover {
      border-color: #999;
    }

    &:focus-within {
      border-color: #0066cc;
      box-shadow: 0 0 0 2px rgba(0, 102, 204, 0.2);
    }
  }

  &__input {
    flex: 1;
    padding: 8px 12px 8px 12px; // Space for search icon
    border: none;
    border-radius: 6px;
    font-size: 14px;
    outline: none;
    background: transparent;

    &::placeholder {
      color: #999;
    }

    &:disabled {
      background-color: #f5f5f5;
      cursor: not-allowed;
    }
  }


  &__edit-btn {
    padding: 6px 12px;
    background: none;
    border: none;
    color: #666;
    cursor: pointer;
    transition: all 0.2s;
    border-radius: 4px;
    margin-right: 4px;

    &:hover {
      color: #0066cc;
      background: rgba(0, 102, 204, 0.1);
    }

    &:disabled {
      color: #999;
      cursor: not-allowed;
    }

    i {
      font-size: 16px;
    }
  }

  &__toggle-btn {
    padding: 6px 12px;
    background: none;
    border: none;
    color: #666;
    cursor: pointer;
    transition: all 0.2s;

    i {
      font-size: 14px;
      transition: transform 0.2s;
      
      &.rotate {
        transform: rotate(180deg);
      }
    }

    &:hover {
      color: #0066cc;
    }

    &:disabled {
      color: #999;
      cursor: not-allowed;
    }
  }

  &__menu {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    margin-top: 4px;
    background: white;
    border: 1px solid #ddd;
    border-radius: 6px;
    box-shadow: 0 2px 8px rgba(0,0,0,0.1);
    z-index: 1000;
  }

  &__item {
    padding: 8px 12px;
    cursor: pointer;

    &:hover {
      background-color: #f5f5f5;
    }

    &--create {
      color: #0066cc;
      border-top: 1px solid #eee;
    }
  }
}