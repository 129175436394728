@use "../../../../variables.scss" as variables;

.status-component {
    display: block;
    width: 100%;
    line-height: 30px;
    font-weight: variables.$font-weight-semibold;
}

.dashboard-title {
    font-family: variables.$font-family-work-sans;
    font-weight: variables.$font-weight-bold;
    color: variables.$primary-night;
    font-size: 2em;
}

.start-project-button {
    background-color: variables.$secondary-button-color !important;
    color: variables.$primary-night !important;

    &:hover {
        background-color: variables.$secondary-night-tint-200 !important;
        color: variables.$primary-night !important;
    }

    &:focus {
        background-color: variables.$secondary-night-tint-200 !important;
        color: variables.$primary-night !important;
    }
}

.status-live {
    background-color: variables.$success-tint-100;
    color: variables.$success;
}

.status-not-live {
    background-color: variables.$alert-tint-100;
    color: variables.$alert;
}

.status-stopped {
    background-color: variables.$danger-tint-200;
    color: variables.$danger;
}

.status-live,
.status-not-live,
.status-stopped {
    padding: 0.4em 0.6em;
    border-radius: 10px;
}

