@import './trace-app-variables.scss';

.journey-wrapper {
  width: 100%;
  height:100vh;
  overflow-x: hidden;
  position: relative;

  background-color: #d8e8e9;


}

.sections-container {
  width: 100%;
  max-width: 100vw;
  background-color: rgba(255, 255, 255, 1);
  @include tablet-only {
    width: 84%;
    margin: auto;
  }

  @include desktop-only {
    width: 60%;
    margin: auto;
  }

  @include desktop-large {
    width: 45%;
    margin: auto;
  }

}

.section-spacing {
  padding: 1rem 0;
  width: 100%;
  overflow: hidden;
}

.container {
  @include tablet-only {
    max-width: map-get($breakpoints, 'md');
  }

  @include desktop-only {
    max-width: map-get($breakpoints, 'lg');
  }
}
