@use "../../../variables.scss" as variables;

.status-active {
    background-color: variables.$success-tint-100;
    color: variables.$success;
}

.status-inactive {
    background-color: variables.$danger-tint-100;
    color: variables.$danger;
}
