/*
* Auth
*/

@use "../../../../variables.scss" as variables;

.signin-text {
    font-size: 3em;
    font-style: normal;
    font-weight: variables.$font-weight-normal;
}


