@import "./../../trace-app-variables";

.header-image {
    width: 100%;
    object-fit: cover;
}

.header-image-container {
    overflow: hidden;
}

.accordion-card {
    border-radius: 15px;
    border: none;
}

.accordion-image {
    height: 185px;
    width: 100%;
    object-fit: fill;
    border-radius: 15px;
}

.accordion-card {
    border-radius: 16px !important;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
    margin-bottom: 1.5rem;

    &:last-child {
        margin-bottom: 0; // Remove margin from last card
    }
    .card-header {
        padding: 0;
        position: relative;
        border: none;
        background: none;

        .top-row {
            position: absolute;
            top: 15px;
            left: 0;
            right: 0;
            display: flex;
            justify-content: space-between;
            padding: 0 15px;
            z-index: 1;
        }

        .accordion-image {
            height: 190px;
            width: 100%;
            object-fit:fill;
            border-radius: 15px;
            transition: border-radius 0.3s ease;

            @include tablet-only {
                height: 350px;
                object-fit: fill;
            }

            @include desktop-only {
                height: 350px;
                object-fit: fill;
            }
        }

        .number-overlay {
            background: rgba(255, 255, 255, 0.9);
            border-radius: 50%;
            width: 30px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: bold;
            color: #333;
        }

        .accordion-icon {
            background: rgba(255, 255, 255, 0.9);
            border-radius: 50%;
            width: 30px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;

            i::before {
                font-size: 12px;
                color: #333333;
                font-weight: 900 !important;
            }
        }

        &.opened {
            .accordion-image {
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
                height: 250px;
                object-fit: fill;

                @include tablet-only {
                    height: 450px;
                    object-fit: fill;
                }

                @include desktop-only {
                    height: 450px;
                    object-fit: fill;
                }
            }
        }

        .overview-section {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            width: 100%;
            text-align: left;
            text-decoration: none;
            background: $white-85;
            backdrop-filter: blur(7px);
            border-radius: 0 !important; // Reset any border radius
            border-bottom-left-radius: 15px !important;
            border-bottom-right-radius: 15px !important;

            .accordion-title {
                flex: 1;
            }

            .accordion-icon {
                background: rgba(0, 0, 0, 0.1);
                border-radius: 50%;
                width: 24px;
                height: 24px;
                display: flex;
                align-items: center;
                justify-content: center;

                i {
                    font-size: 12px;
                    color: #333;
                }
            }

            &.opened {
                border-bottom-left-radius: 0 !important;
                border-bottom-right-radius: 0 !important;
            }

            i {
                font-size: 0.875rem;
            }
        }
    }

    .card-body {
        padding: 1.5rem;
    }
}

.accordion-card.landing {
    .accordion-image {
        height: 318px;
    }
}

.process-name {
    font-size: map-get($font-sizes, "xs") !important;
    font-weight: $font-weight-normal;
    letter-spacing: -0.05em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: rgba(30, 30, 30, 1);

    @include tablet-only {
        font-size: map-get($font-sizes-tablet, "base") !important;
    }

    @include desktop-only {
        font-size: map-get($font-sizes-desktop, "base") !important;
    }
}

.location-name {
    font-size: map-get($font-sizes, "base") !important;
    font-weight: $font-weight-bold !important;
    line-height: 1.2 !important;
    letter-spacing: -0.05em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: $primary-teal;

    @include tablet-only {
        font-size: map-get($font-sizes-tablet, "xl") !important;
    }

    @include desktop-only {
        font-size: map-get($font-sizes-desktop, "xl") !important;
    }
}

.date {
    font-size: map-get($font-sizes, "xxs") !important;
    font-weight: $font-weight-normal;
    line-height: 1.2;
    letter-spacing: -0.05em;
    text-align: right;
    text-underline-position: from-font;
    color: rgba(29, 31, 30, 0.75);
    text-decoration-skip-ink: none;

    @include tablet-only {
        font-size: map-get($font-sizes-tablet, "sm") !important;
    }

    @include desktop-only {
        font-size: map-get($font-sizes-desktop, "sm") !important;
    }
}

.info-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
}

.info-item {
    display: flex;
    align-items: center;
    gap: 15px;

    .file-link {
        color: #007bff; // Bootstrap primary blue color
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }
}

.icon {
    display: flex;
    align-items: center;
    justify-content: center;
    img {
        padding: 0.3rem;
        width: 1.5rem;
        object-fit: contain;
        background: $primary-teal;
        border-radius: 2px !important;

        @include tablet-only {
            width: 2.5rem;
        }

        @include desktop-only {
            width: 2.5rem;
        }
    }
    .bi-info-circle {
        padding: 0.3rem;
        padding-bottom: 0;
        padding-top: 0;
        width: 1.5rem;
        object-fit: contain;
        background: $primary-teal;
        border-radius: 2px !important;
        color: #fff;
    }
}

.info-item-title {
    color: $primary-teal;
    font-size: map-get($font-sizes, "xxs") !important;
    font-weight: $font-weight-medium;
    letter-spacing: -0.03em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;

    @include tablet-only {
        font-size: map-get($font-sizes-tablet, "sm") !important;
    }

    @include desktop-only {
        font-size: map-get($font-sizes-desktop, "sm") !important;
    }
}

.info-item-subtitle {
    color: rgba(29, 31, 30, 0.8);
    font-size: map-get($font-sizes, "xxs") !important;
    font-weight: $font-weight-medium;
    line-height: 1rem;
    letter-spacing: -0.05em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;

    @include tablet-only {
        font-size: map-get($font-sizes-tablet, "sm") !important;
    }

    @include desktop-only {
        font-size: map-get($font-sizes-desktop, "sm") !important;
    }
}

/* FacilityCard.css */
.sri-lanka-image {
    width: 100%;
    height: 100%;
    object-fit: cover;

    @include tablet-only {
        height: 350px;
        width: auto;
    }

    @include desktop-only {
        height: 350px;
        width: auto;
    }
}
.facility-title {
    font-size: map-get($font-sizes, "xxs") !important;
    font-weight: 500;
    color: #000;
    margin-bottom: 10px;

    @include tablet-only {
        font-size: map-get($font-sizes-tablet, "sm") !important;
    }

    @include desktop-only {
        font-size: map-get($font-sizes-desktop, "sm") !important;
    }
}

.facility-info {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.info-row {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.info-label {
    font-size: map-get($font-sizes, "xxs") !important;
    color: $primary-teal;
    margin: 0;
    @include tablet-only {
        font-size: map-get($font-sizes-tablet, "sm") !important;
    }

    @include desktop-only {
        font-size: map-get($font-sizes-desktop, "sm") !important;
    }
}

.info-value {
    font-size: map-get($font-sizes, "xxs") !important;
    color: #666;
    margin: 0;
    @include tablet-only {
        font-size: map-get($font-sizes-tablet, "sm") !important;
    }

    @include desktop-only {
        font-size: map-get($font-sizes-desktop, "sm") !important;
    }
}

.facility-map {
    height: 300px;
    width: 100%;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
