@use "../../../../variables.scss" as variables;

.text-overflow {
    overflow: hidden; /* Ensures the content is not displayed outside the button */
    text-overflow: ellipsis; /* When the content overflows, it's replaced with '...' */
    white-space: wrap; /* Keeps the text in a single line */
}

.default-payment-text {
    font-weight: variables.$font-weight-medium;
}

.plus-icon {
    font-size: 1.5em;
    font-weight: variables.$font-weight-bold;
}

