@use "../../../variables.scss" as variables;

.landing-container {
    padding: 0px;
    background: transparent
        linear-gradient(
            122deg,
            variables.$ghost-white 0%,
            variables.$alice-blue 100%
        )
        0% 0% no-repeat padding-box;
}

.container-fluid {
    padding: 0px;
}

.image-container {
    position: relative;
    height: 200px;
    width: 200px;
}

.circle {
    position: absolute;
    height: 150px;
    width: 150px;
    border-radius: 50%;
    background: variables.$secondary-argentinian-blue-tint-400;
    z-index: 1;
}

.image {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
}

.trace-card {
    border-radius: 15px;
    box-shadow: 0px 4px 4px 0px #00000040;
    border: none;
    cursor: pointer;
    .trace-image {
        border-radius: 15px;
    }
}

.product-image {
    width: 150px;
    height: 150px;
    border-radius: 50%;
}

.landing-header {
    background-color: white;
}

.code-input {
    width: 40px;
    height: 40px;
    font-family: variables.$font-family-public-sans;
    font-size: 1em;
    border-radius: 0 !important;
    font-weight: 500;
    background-color: transparent !important;
    border: 1px solid variables.$secondary-night-tint-400;
    &:focus {
        color: variables.$primary-color-blue;
        font-family: variables.$font-family-public-sans;
        font-style: normal;
    }
}
